





































































































import Vue from "vue";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      query: "",
      data: [
        {
          name: "Zahnarzt",
          image:
            "https://image.stern.de/6219354/t/y0/v4/w1440/r1/-/sprueche-arzt-jpg--e18824db7cb6f7d1-.jpg",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
        {
          name: "Hausarzt",
          image:
            "https://www.medizinjobs-direkt.de/blog/wp-content/uploads/2018/11/05.11.2018_Als-deutscher-Arzt-in-Europa-Karriere-machen.jpg",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
        {
          name: "Cardiologe",
          image:
            "https://www.erfolg-als-freiberufler.de/wp-content/uploads/2016/11/freiberuflicher-arzt.jpg",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
        {
          name: "Hautarzt",
          image:
            "https://www.vital.de/sites/default/files/styles/facebook/public/media/2020/04/Arzt-17463.jpg?itok=wDfe33fp",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
        {
          name: "Physiotherapeut",
          image:
            "https://www.praktischarzt.de/wp-content/themes/praktischArzt-theme/img/arzt/Assistenzarzt-Final.jpg",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
        {
          name: "Neurologe",
          image:
            "https://www.krankenhaus.de/fileadmin/_processed_/a/9/csm_Doktor_No_8a5122cf5f.jpg",
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam",
        },
      ],
    };
  },
  methods: {
    viewResults(): void {
      if (this.query.length > 0) {
        this.$router.push(`/results/${this.query}`);
      }
    },
  },
  computed: {
    items(): Array<string> {
      return this.data.map((a) => a.name);
    },
  },
});
