import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Registration from "../views/Registration.vue";
import UserPage from "../views/UserPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/user",
    name: "User",
    component: UserPage,
  },
  {
    path: "/results/:query",
    name: "Results",
    component: () =>
      import(
        /* webpackChunkName: "resultOverview" */
        "../views/ResultOverview.vue"
      ),
  },
  {
    path: "/details/:id",
    name: "Praxis Details",
    component: () =>
      import(
        /* webpackChunkName: "praxisDetails" */
        "../views/PraxisDetails.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

export default router;
