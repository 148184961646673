
















































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      show: false,
      email: "",
      password: "",
      valid: false,
      //RULES
      notEmpty: function (input: string) {
        if (input && input.length > 0) {
          return true;
        }
        return "Pflichtfeld";
      },
    };
  },
  methods: {
    login(): void {
      let raw = JSON.stringify({
        username: this.email,
        password: this.password,
      });

      let requestOptions = {
        method: "POST",
        body: raw,
      };

      fetch("https://mdic-backend.herokuapp.com/login", requestOptions)
        .then((response) => response.text())
        .then((token) => {
          this.$store.dispatch("setLoginToken", token);
          this.$router.push("/");
        })
        .catch((error) => console.log("error", error));
    },
  },
});
