




















import Vue from "vue";
export default Vue.extend({
  name: "AppBar",
  computed: {
    isSignedIn(): string {
      return this.$store.getters.getToken;
    },
  },
});
