


























































































































































































import Vue from "vue";

export default Vue.extend({
  data: () => ({
    // Selection salutation
    items: ["Herr", "Frau"],

    // Datepicker
    date: new Date().toISOString().substr(0, 10),
    menu: false,

    firstname: "",
    lastname: "",
    gender: "Frau",
    email: "",
    password: "",
    passwordConfirm: "",
    phonenumber: "",
    houseNumber: "",
    streetName: "",
    plz: "",
    city: "",
    birthdate: "",
    state: "",
    country: "",

    // Password
    show: false,

    // Requirement rules
    rules: {
      required: (value: string) => !!value || "Pflichtfeld",
      emailMatch: () => "Die Email und das Passwort stimmen nicht über ein",
      isNumber: (value: string) => /^\d+$/.test(value) || "Nur Zahlen erlaubt",
      isEmail: (value: string) =>
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) ||
        "Keine gültige Email",
      isValidPassword: (value: string) =>
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          value
        ) ||
        "Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl, einen Buchstaben und ein Sonderzeichen enthalten",
    },
  }),
  methods: {
    register() {
      if (this.password !== this.passwordConfirm) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        firstname: this.firstname,
        lastname: this.lastname,
        birthdate: this.birthdate,
        gender: this.gender,
        email: this.email,
        password: this.password,
        address: {
          streetName: this.streetName,
          houseNumber: this.houseNumber,
          city: this.city,
          state: this.state,
          plz: this.plz,
        },
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/registration/patient",
        requestOptions
      )
        .then((response) => response.text())
        .then(() => this.$router.push({ path: "home" }))
        .catch((error) => console.log("error", error));
    },
  },
});
